import { PERMISSION_CHECK, CLEAR_PERMISSIONS } from './types';

const checkPermissionAciton = (user) => {
  return {
    type: PERMISSION_CHECK,
    payload: user,
  };
};

const clearPermissionsAction = (payload) => {
  return {
    type: CLEAR_PERMISSIONS,
    payload,
  };
};

export const checkPermission = (user) => (dispatch) => {
  dispatch(checkPermissionAciton(user));
};

export const clearPermissions = (user) => async (dispatch) => {
  dispatch(clearPermissionsAction(user));
};

